/* import { XRManager } from "./xrManager.model"; */
import { ProductManager } from "./productManager.js";
import { UIManager } from "./uiManager.js";
import { CameraManager } from './cameraManager.js';
import { AudioManager } from './audioManager.js';

export class AppManager{  
    constructor(scene, engine, dataResult){
        console.log(dataResult);
        this.scene = scene;
        this.engine = engine;
        this.currentStepNumber = 0;
        this.data = dataResult;

        this.cameraManager = new CameraManager(this, dataResult.targetPart);
        this.productManager = new ProductManager(dataResult, this)
        this.audioManager = new AudioManager(this);
        this.uiManager = new UIManager(this);
        this.sceneActive = false;
        this.currentProcedure = null; 
        /* this.xrManager = new XRManager(scene, engine); */
        var url = new URL(window.location.href);
        var text = url.searchParams.get("exp");
        let exp = text.replace("_", " ");
        exp = exp.replace("_", " ");
        exp = exp.replace("_", " ");
        document.getElementById("experience-title").innerHTML = "Welcome to "+exp+" Training";
        //document.getElementById("menu-title").innerHTML = dataResult;

    }

    startScene(){
        document.getElementById("loading").style.display = "none";
        this.sceneActive = true;
        this.cameraManager.activateCamera();
        this.productManager.animationManager.stopAnimation();
    }

    startNewProcedure(procedureName){
        this.currentProcedure = this.productManager.findProcedureByName(procedureName);
        this.uiManager.displayInstructionsScreen();
        this.productManager.animationManager.playAnimation(this.currentProcedure.steps[this.currentStepNumber].start, this.currentProcedure.steps[this.currentStepNumber].start);
        /* this.productManager.importedModel.animationGroups.forEach(function (animationGroup) {
            animationGroup.start(false, 1, this.currentProcedure.steps[this.currentStepNumber].start / 24, this.currentProcedure.steps[this.currentStepNumber].start / 24); 
        });  */

        this.uiManager.displayStep(this.currentProcedure.steps[this.currentStepNumber]);

    }

    playAnimation(){
        var step = this.currentProcedure.steps[this.currentStepNumber];
        this.productManager.animationManager.playAnimation(step.start, step.end);
        this.uiManager.hideInstructionsScreen();
        this.currentStepNumber++;
    }

    startNextStep(){
        //this.currentStepNumber++;
        this.uiManager.displayInstructionsScreen();
        var step = this.currentProcedure.steps[this.currentStepNumber];
        this.uiManager.displayStep(step);
        if(step != undefined){
            this.cameraManager.checkForHighlights(step.partsToHighlight);
            this.cameraManager.checkForHidden(step.partsToHide);
            this.cameraManager.checkForCamera(step.camera.cameraName);
        }
    }
}