
export class Step{  
    constructor(stepData, stepID){
        this.step = stepID;
        this.start = stepData.start;
        this.end = stepData.end;
        this.stepDescription = stepData.stepDescription || " ";
        this.stepSubDescription = stepData.stepDescriptionSubTitle || " ";
        this.tipDescription = stepData.tipDescription || "null";
        this.partsToHighlight = stepData.partsToHighlight || "null";
        this.partsToHide = stepData.partsToHide || "null";
        this.inputRequired = stepData.inputRequired.title || "null";
        this.camera = stepData.camera || "null";
        
        if(this.stepDescription!="Description not found")
            this.stepDescription = "Step "+this.step + ". "+this.stepDescription;
    }
}