import { ControlPanel } from '../models/controlPanel';
import { Joystick } from '../models/joystick';
import { ConfirmButton } from '../models/confirmButton';

export class UIManager{  

    constructor(appManager){
        this.appManager = appManager;
        this.stepNumber = 0;
        this.initialise();
    }

    initialise(){
        this.canvas = document.getElementById("renderCanvas");
        this.homeScreen = document.getElementById("home");
        this.instructionsScreen = document.getElementById("instructions");
        this.stepDescription = document.getElementById("stepDescription");
        this.stepSubDescription = document.getElementById("stepSubDescription");
        this.joysticksContainer = document.getElementById("joysticksContainer");
        this.controlPanelContainer = document.getElementById("controlPanelContainer");
        this.confirmButtonContainer = document.getElementById("confirmButtonContainer");
        this.procedureCompleteContainer = document.getElementById("procedureCompleteContainer");
        this.controlBoxTip = document.getElementById("controlBoxTip");
        
        this.joystickLeft = new Joystick(this,"Left");
        this.joystickRight = new Joystick(this,"Right");
        this.controlPanel = new ControlPanel(this);
        this.confirmButton = new ConfirmButton(this);
        this.initialiseProcedureButtons();
        this.initialiseHomeButton();
        this.displayHomeScreen();
    }

    initialiseHomeButton(){
        var self = this;
        this.homeButton = document.getElementById("homeButton");
        this.homeButton.addEventListener('click',function(event){ 
            self.displayHomeScreen();
        }); 
    }

    initialiseProcedureButtons(){
        var self = this;
        this.procedureButtonTemplate = document.getElementById("procedureButton");
        for(var i = 0; i < this.appManager.productManager.procedures.length; i++){
            var procedure = this.appManager.productManager.procedures[i];
            var btn = this.procedureButtonTemplate.cloneNode(true); 
            this.procedureButtonTemplate.parentNode.appendChild(btn);
            btn.innerText =  procedure.procedureName;
            btn.style.display = "block";
            btn.addEventListener('click',function(event){self.appManager.startNewProcedure(event.target.innerHTML);}); 
        }
    }

    displayHomeScreen(){
        this.appManager.currentStepNumber = 0;
        this.instructionsScreen.style.display = "none";
        this.homeScreen.style.display = "block";
        this.appManager.audioManager.stopSounds();
        if(this.appManager.productManager.animationManager){
            this.appManager.productManager.animationManager.playAnimation(0,0);
            this.appManager.productManager.nextStepNumber = this.appManager.currentStepNumber + 1;
            this.appManager.cameraManager.checkForHighlights("null")
            this.appManager.cameraManager.checkForHidden("null")

        }
    }
    displayInstructionsScreen(){
        this.homeScreen.style.display = "none";
        this.instructionsScreen.style.display = "block";
    }
    hideInstructionsScreen(){
        this.instructionsScreen.style.display = "none";
    }

    displayStep(step){
        this.clearLastStep();
        
        if(step == undefined){
            this.procedureCompleteContainer.style.display = "flex";
            this.appManager.audioManager.stopSounds();
            return;
        }
        console.log(step)
        this.appManager.cameraManager.checkForHighlights(step.partsToHighlight)
        this.appManager.cameraManager.checkForHidden(step.partsToHide)

        this.stepDescription.innerHTML = step.stepDescription;
        this.stepSubDescription.innerHTML = step.stepSubDescription;
        this.controlBoxTip.innerHTML = step.tipDescription;
        if(step.inputRequired.includes("Joystick")){
            this.joysticksContainer.style.display = "flex";
            this.joystickLeft.setActiveArrow(step.inputRequired);
            this.joystickRight.setActiveArrow(step.inputRequired);
        }
        else if(step.inputRequired.includes("Panel")){
            if(step.inputRequired.includes("unlock"))
                this.controlPanel.isLocked = true;
            else
                this.controlPanel.isLocked = false; 
            this.controlPanel.setState();
            this.controlPanelContainer.style.display = "flex";
        }
        else if(step.inputRequired.includes("Confirm")){
            this.confirmButtonContainer.style.display = "flex";
        }
    }

    clearLastStep(){
        this.stepDescription.innerHTML = "";
        this.stepSubDescription.innerHTML = "";
        this.joysticksContainer.style.display = "none";
        this.controlPanelContainer.style.display = "none";
        this.confirmButtonContainer.style.display = "none";
        this.procedureCompleteContainer.style.display = "none";
    }
}

